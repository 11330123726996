import "./style/base.css"
import "./style/contact.css"

function Contact() {
    return (
        <>
            <h1>PLACEHOLDER CONTACT PAGE</h1>
        </>
    );
}

export default Contact;