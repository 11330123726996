import "./style/base.css"
import "./style/projects.css"

function Projects() {
    return (
        <>
            <h1>PLACEHOLDER PROJECT PAGE</h1>
        </>
        
    );
}

export default Projects;